export const SET_TABLE = "setTable";

export default {
  state: {
    tables: [],
  },
  getters: {
    getTables(state) {
      return state.tables;
    },
  },
  actions: {
    [SET_TABLE](state, payload) {
      state.commit(SET_TABLE, payload);
    },
  },
  mutations: {
    [SET_TABLE](state, payload) {
      state.tables = {
        ...state.tables,
        ...payload,
      };
    },
  },
};
