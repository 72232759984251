

<template>

  <div class="bg-home vh100">
    <img src="~@/assets/header.png" class="mt-0">


    <div class="container ">
      <div class="title1 text-white text-center">ยินดีต้อนรับสู LINE OA</div>
      <div class="title2 text-white text-center">สำหรับเจ้าหน้าที่</div>

      <center>

        <button type="button" @click="register" class="submit-button  mt-5">
          ลงทะเบียนเข้าร่วมงาน
        </button>


      </center>

      <center>
        <button type="button" @click="close" class="submit-button  mt-3">
          กลับไปหน้าไลน์
        </button>
      </center>


    </div>

  </div>


</template>

<script>
import { defineComponent, onMounted, inject } from "vue";
import liff from "@line/liff";
export default defineComponent({
  name: "Dashboard_home",

  setup() {
    const store = inject("store");
    const router = inject("router");






    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const register = () => {

      router.push({ name: "Register_1" });

    };


    const close = () => {
      liff.ready.then(() => {
        liff.closeWindow();
      });
    };

    return {
      register,
      close
    };
  },
});
</script>

<style scoped>
.header {
  background-image: url("~@/assets/header.png");
  object-fit: cover;
  height: 150px;
  border: none;
  padding: 0;
}

.title1 {
  font-size: 16px;
  font-weight: medium;
}

.title2 {
  font-size: 25px;
  font-weight: medium;
}

.icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: #4E6629;
}


.card {
  color: #4E6629;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 250px;
  border-radius: 25px;
  padding: 5px;
  background-image: url("~@/assets/card-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 0%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.card-header {
  margin-top: 30px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-picture {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}

.profile-picture img {
  width: 100%;
}

.card-footer {
  border: none;
  background-color: transparent;
  justify-content: end;
}

.logo-picture {
  width: 120px;
  height: 120px;
  overflow: hidden;
}

.logo-picture img {
  width: 100%;
}

.submit-button {
  font-size: 28px;
  color: #4E6629 !important;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  height: 80px;
  border-radius: 25px;
  border: none;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 8px 10px 8px 10px;
}

.submit-button:hover,
.submit-button:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.submit-button:hover {
  transform: translateY(-1px);
}

.submit-button:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}


.bg-home {
  background-color: var(--color);
  overflow-y: scroll;
  padding-bottom: 20px;
}

.bg-home img {
  position: relative;
  object-fit: cover;
  width: 100%;
  border: none;
  height: auto;
  padding-top: 0;

}
</style>


